import { Button, Form, Modal } from "react-bootstrap";
import { customFormError, isDuplicateMail } from "../../helper/helperFunction";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import regx from "../../staticfiles/svgPack/regex";
import { useDispatch } from "react-redux";
import { handleCheckHouseHold } from "../../Redux/actions/household";
import { useState } from "react";
const AddHousematesModal = ({
  handleSubmitGmail,
  registerGmail,
  setValue,
  defaultValues,
  setError,
  errors,
  reset,
  houseHoldIndex,
  clearErrors,
  userData,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const common_cb = () => {
    rest?.onHide();
    //after adding the mail resetting the email text in input
    reset();
    clearErrors("household");
  };
  const errCB=()=>{
    customFormError(setError, "email", `User is already part of a household. They need to leave their current household to be added to yours`);
  }
  const handleAppendTenant = (data) => {
    const { tenants } = defaultValues;

    //checking is there any duplicate mail already exists
    const isDuplicate = isDuplicateMail(tenants, data, userData);
    if (isDuplicate)
      return customFormError(setError, "email", "Email already exists");

    // when we are adding the mail we are checking on the backend if the user has account  or not. if account cb_1 will call otherwise cb is call
    const cb = () => {
      setValue(
        "tenants",
        tenants.map((tenant, index) =>
          index === houseHoldIndex ? { ...tenant, ...data } : tenant
        ),
        {
          shouldValidate: true,
        }
      );
      common_cb();
    };
    const cb_1 = (name,proImg) => {
      const extraKeyName = "name";
      const extraNameValue = name;
      const extraKeyImg="profilePic"
      const extraImgValue=proImg

      const updatedData = { ...data, [extraKeyName]: extraNameValue ,[extraKeyImg]:extraImgValue};
      setValue(
        "tenants",
        tenants.map((tenant, index) =>
          index === houseHoldIndex ? { ...tenant, ...updatedData } : tenant
        ),
        {
          shouldValidate: true,
        }
      );
      common_cb();
    };

    let obj = { data, cb, cb_1,setLoading,errCB };
    dispatch(handleCheckHouseHold(obj));
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="py-md-40px px-md-32px">
        <Form className="px-2" onSubmit={handleSubmitGmail(handleAppendTenant)}>
          <div className="text-center">
            <h3 className="fsr-24 fw-700">Add your housemates</h3>
            <p className="text-gray fw-500 mb-34">
              All you need is their email address (even if they don’t have a
              Rently account!)
            </p>

            <div className="form-floating-v1 customizedv1 mb-3">
              <Form.Floating>
                <Form.Control
                  id="floatingPhoneNumberCustom"
                  type="text"
                  placeholder="Enter their email address"
                  {...registerGmail("email", {
                    required: {
                      value: true,
                      message: FormErrorMessage.email,
                    },
                    pattern: {
                      value: regx?.email,
                      message: FormErrorMessage?.emailFormat,
                    },
                  })}
                />
                <label
                  htmlFor="floatingPhoneNumberCustom"
                  className="text-center text-nowrap"
                >
                  Enter their email address
                </label>
              </Form.Floating>
              {errors?.email && (
                <div className="errorMsgV1 ms-3 visible text-start">
                  {errors?.email?.message}
                </div>
              )}
            </div>
          </div>

          <Button
            variant="primary"
            className="w-100 fsr-14 fw-700 mb-3"
            size="lg"
            type="submit"
            disabled={loading}
          >
            Add to your household
          </Button>

          <div className="d-flex fsr-14 align-items-start gap-2 text-gray-v2 fw-500">
            <img src="/img/infoV1.svg" alt="" />
            <div>
              <p>
                If they have an account, we’ll email them asking to verify your
                request.
              </p>

              <p>
                If they don’t have an account, we’ll email them asking to create
                an account.
              </p>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddHousematesModal;
