import InputEditElement from "./inputElem";
import { Button } from "react-bootstrap";

import regx from "../../staticfiles/svgPack/regex";
const MailOperation =({userData,showEdit,errorsGmail,handleSubmitGmail,onSubmitEditGmail,setEditShow,loading,registerGmail})=>{



    return(
        <div className="position-relative inputV1">
        <InputEditElement
          type="email"
          placeholder="email"
          className="h-100"
          defaultValue={userData?.email}
          disabled={!showEdit.isEditGmail}
          id={"email"}
          register={registerGmail}
          validationSchema={{
            pattern: {
              value: regx.email,
              message: "Invalid email",
            },
          }}
          name={"email"}
          errors={errorsGmail?.email}
        />
        <Button
          variant="dark"
          className="position-absolute end-0 top-0 h-100 fw-700 px-3 min-w-100px"
          onClick={
            showEdit.isEditGmail
              ? handleSubmitGmail(onSubmitEditGmail)
              : () =>
                  setEditShow({
                    ...showEdit,
                    isEditGmail: !showEdit.isEditGmail,
                  })
          }
          disabled={loading}
        >
          {showEdit.isEditGmail ? "UPDATE" : "EDIT"}
        </Button>
      </div>
    )
}

export default MailOperation;