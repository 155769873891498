import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HelpModal from "../../components/HelpModal";
import ModalComponent from "../../components/Modal/Modal";
import EditProfileModal from "../ViewAccModals/EditProfileModal";
import "./Header.scss";
import { RENTLY_HELP_LINK } from "../../Config/config";
const Header = ({ showHelp = false }) => {
  const userData = useSelector((store) => store?.tenantAuth?.userData);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const location = useLocation();
  const path = location?.pathname;
  const navigate = useNavigate();
  return (
    <header className="mainHeader">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Link to={userData?.jwtToken ? "/profile" : "/"}>
            <img src="/img/logo.svg" alt="logo" />
          </Link>
        </div>
        {userData && path === "/profile" && (
          <Button
            variant="white"
            className="ms-auto border-0 fsr-18 fw-700 px-md-0 px-4 me-md-4 py-0 hoverBtn scaleDown"
            onClick={() => setModalShow1(true)}
            size="lg"
          >
            <div className="d-flex justify-content-center align-items-center">
              <img src="/img/account.png" alt="edit" className="accountImg" />
            </div>
            {/* <p className="mb-0 d-none d-md-block">My Account</p> */}
          </Button>
        )}
        {showHelp && (
          <div className="d-flex helpBtnDiv">
            <Link
              variant="white border-0 px-0"
              className="px-0 py-0 hoverBtn scaleDown"
              to={RENTLY_HELP_LINK}
              size="lg"
              target="_blank"
            >
              <img src="/img/helpLogo.svg" alt="logo" />
            </Link>
          </div>
        )}
      </div>
      <ModalComponent show={modalShow1} onHide={() => setModalShow1(false)}>
        <EditProfileModal
          userData={userData}
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        />
      </ModalComponent>
      {/* <HelpModal show={modalShow2} onHide={() => setModalShow2(false)} /> */}
    </header>
  );
};

export default Header;
