import { getEnvPath } from "../../helper/helperFunction";
import imagePack from "../../staticfiles/imagePack";
const Tenant = ({
  userData,
  label,
  img,
  houseHold_id,
  onDelete,
  tenId,
  proImg,
  index,
}) => {
  // if(!tenId) return
  return (
    <div className="text-center text-gray-v2 w-fit fw-500 position-relative">
      <div className="imgWrapV3 mb-2">
        {tenId ? (
          <img
            src={
              proImg
                ? getEnvPath("compressed-profilePic", proImg)
                : imagePack?.blankProfile
            }
            alt="person"
            className="roundedImgDefault"
          />
        ) : (
          <img
            src={getEnvPath("compressed-profilePic", img)}
            alt="person"
            className="roundedImgDefault"
          />
        )}
      </div>
      {/* delete */}
      {
        <span className="position-absolute top-0 end-0 cursor-pointer">
          <img
            src="/img/cross-round-icon.svg"
            alt="delete"
            className=""
            onClick={() => onDelete(tenId)}
          />
        </span>
      }
      <span>{label}</span>
    </div>
  );
};

export default Tenant;
