import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { GetAd } from "../../API/Ad/GetAd";
import { changePasswordApi } from "../../API/Auth/ChangePassword/changePassword";
import { CredentialChangeApi } from "../../API/Auth/CredentialChange/CredentialChange";
import { deleteUserApi } from "../../API/Auth/DeleteUser/deleteUser";
import { ForgetPassword } from "../../API/Auth/ForgetPassword/forgetPassword";
import { Login as LoginApi } from "../../API/Auth/Login/login";
import { ProfileUpdate } from "../../API/Auth/ProfileUpdate/ProfileUpdate";
import { Register as RegisterApi } from "../../API/Auth/Register/Register";
import { updatePasswordApi } from "../../API/Auth/UpdatePassword/UpdatePassword";
import { uploadFile } from "../../API/Auth/UploadProfile/UploadProfile";
import { verifyUser } from "../../API/Auth/verifyUser";
import { GetHouseHold } from "../../API/HouseHold/GetHouseHold";
import { GetTemplateApi } from "../../API/offerTemplate/GetTemplate";
import { ResetOfferTemplateApi } from "../../API/offerTemplate/ResetTemplate";
import { UpdateOfferTemplateApi } from "../../API/offerTemplate/UpdateOfferTemplate";
import { handleApiError } from "../../helper/helperFunction";
import { clearData, setAcc } from "../slices/createAccSlice";
import { clearHouseHold, setHouseHold } from "../slices/houseHoldSlice";
import { clearLeadsData } from "../slices/leadsSlice";
import {
  clearAuth,
  setAd,
  setAuth,
  setOfferTemplate,
  setUser,
} from "../slices/userSlice";
import { ShareProfileApi } from "../../API/shareProfile";
export const handleRegisterTenant = createAsyncThunk(
  "tenant/createAcc",
  async ({ data, setLoading, cb, leadId }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await RegisterApi(data);
      const responseData = resp?.data;
      if (responseData?.userData) {
        const userData = { ...responseData?.userData, leadId };
        dispatch(setAcc(userData));
        toast.success("Account created Successfully");
        cb(userData);
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleLoginTenant = createAsyncThunk(
  `tenant/tenantLogin`,
  async ({ data, setLoading, cb }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await LoginApi(data);

      if (resp && resp.data) {
        dispatch(setUser(resp?.data?.userData));
        dispatch(setAuth(true));

        if (resp?.data?.userData?.isProfileCompleted) {
          const res = await GetHouseHold();

          dispatch(setHouseHold(res?.data?.houseHold));
        }
        toast.success("Successfully Logged In");
        // cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleForgetPassword = createAsyncThunk(
  `tenant/forgetPassword`,
  async ({ data, cb }, { dispatch }) => {
    try {
      const resp = await ForgetPassword(data);

      if (resp) {
        toast.success("Reset password link sent to email");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);

export const verifyUserReducer = createAsyncThunk(
  `tenant/verifyUser`,
  async (_, { dispatch, getState }) => {
    try {
      const state = getState(); // Access the entire Redux state
      const userData = state.tenantAuth.userData;
      const res = await verifyUser();
      if (res.success) {
        dispatch(setAuth(true));
        dispatch(setUser({ ...res.userData, jwtToken: userData.jwtToken }));
      } else {
        dispatch(setAuth(false));
        dispatch(setUser(null));
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);

export const handleChangePassword = createAsyncThunk(
  `tenant/changePassword`,
  async ({ data, jwtToken, cb, setLoading }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await changePasswordApi(data, jwtToken);
      if (resp.status === 401) {
        // Handle the 401 status code with a custom error message
        throw new Error("Unauthorized: Please log in again.");
      }

      if (resp) {
        toast.success("Password Changed Successfully");
        cb();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
      setLoading(false);
    }
  }
);

export const profileComplete = createAsyncThunk(
  `tenant/profile-update`,
  async (
    { finalData, cb, setLoading, setDisabled },
    { dispatch, getState }
  ) => {
    try {
      setLoading(true);
      const state = getState();
      const userData = state.tenantAuth.userData;

      const res = await ProfileUpdate(finalData);
      if (res.success) {
        dispatch(setAuth(true));
        dispatch(
          setUser({
            ...res?.data?.userData,
            leadUpdatedAt: userData?.leadUpdatedAt,
            leadId: res?.leadId ?? userData?.leadId,
            jwtToken: res?.data?.userData.jwtToken,
          })
        );

        cb();
      } else {
        dispatch(setAuth(false));
        dispatch(setUser(null));
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
      setDisabled(true);
    }
  }
);

export const handleCredentialsChange = createAsyncThunk(
  "tenant/changCred",
  async ({ data, setLoading, cb }, { dispatch, getState }) => {
    try {
      //email, number => uupdate-user
      // pass=> change-password
      setLoading(true);
      const state = getState(); // Access the entire Redux state
      const userData = state.tenantAuth.userData;
      const resp = await CredentialChangeApi(data);
      if (resp.success) {
        const user = resp?.data?.userData;
        dispatch(
          setUser({ ...userData, email: user.email, phone: user?.phone })
        );
        toast.success("Successfully updated");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleUpdatePassword = createAsyncThunk(
  "tenant/changCred",
  async ({ data, setLoading, cb }, { dispatch }) => {
    try {
      setLoading(true);

      const resp = await updatePasswordApi(data);
      if (resp.success) {
        dispatch(setUser(null));
        dispatch(set);
        toast.success("Successfully update the password");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const logOutUser = createAsyncThunk(
  `tenant/logout-user`,
  async (cb, { dispatch, getState }) => {
    try {
      dispatch(setAuth(false));
      dispatch(setUser(null));
      dispatch(clearAuth());
      dispatch(clearHouseHold());
      dispatch(clearData());
      dispatch(clearLeadsData());
      toast.success("User logged out successfully");
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);
export const deleteUser = createAsyncThunk(
  `tenant/delete-user`,
  async (cb, { dispatch, getState }) => {
    try {
      const state = getState(); // Access the entire Redux state
      const userData = state.tenantAuth.userData;
      const res = await deleteUserApi(userData?._id);
      if (res.success) {
        dispatch(setAuth(false));
        dispatch(setUser(null));
        toast.success("User deleted successfully");
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);

export const uploadUserProfile = createAsyncThunk(
  `tenant/upload-Profile`,
  async ({ file, setLoading, cb }, { dispatch, getState }) => {
    try {
      const state = getState(); // Access the entire Redux state
      const userData = state.tenantAuth.userData;
      const form_data = new FormData();

      const id = toast.loading("Uploading...");

      form_data.append("profilePic", file);
      const res = await uploadFile(form_data, setLoading, id);
      if (res.success) {
        const user = res?.data?.userData;
        dispatch(setUser({ ...userData, profilePic: user?.profilePic }));

        toast.success("Successfully uploaded!", { id: id });
        setLoading(false);
        cb();
      }
    } catch (error) {
      toast.error("Upload failed", { id: id });
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);

export const handleUpdateOfferTemplate = createAsyncThunk(
  "tenant/update-template",
  async ({ data, setLoading }, { dispatch }) => {
    try {
      setLoading(true);

      const resp = await UpdateOfferTemplateApi(data);
      if (resp?.success) {
        dispatch(setOfferTemplate(resp.data?.offerTemplateData));
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handelGetTemplate = createAsyncThunk(
  `tenant/template`,
  async ({ setLoading }, { dispatch, getState }) => {
    try {
      setLoading(true);
      const resp = await GetTemplateApi();
      if (resp?.success) {
        dispatch(setOfferTemplate(resp.data?.offerTemplateData));
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      // toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleResetOfferTemplate = createAsyncThunk(
  "tenant/reset-template",
  async ({ setLoading }, { dispatch }) => {
    try {
      setLoading(true);

      const resp = await ResetOfferTemplateApi();
      if (resp?.success) {
        dispatch(setOfferTemplate(resp.data?.offerTemplateData));
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleFetchAd = createAsyncThunk(
  "tenant/Ad",
  async ({ type, setLoading }, { dispatch }) => {
    try {
      setLoading(true);

      const resp = await GetAd(type);
      if (resp.success) {
        const data = resp?.data;
        dispatch(setAd(data?.advertisementData));
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
      dispatch(clearHouseHold());
    } finally {
      setLoading(false);
    }
  }
);

export const ShareProfileAction = createAsyncThunk(
  "tenant/share-profile",
  async ({ data, setLoading, cb }, { dispatch, getState }) => {
    try {
   
      setLoading(true);

      const resp = await ShareProfileApi(data);
      if (resp.success) {
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);
