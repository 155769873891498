import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: null,
};

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setLeadsDetails: (state, action) => {
      state.details = action.payload;
    },
    clearLeadsData: () => initialState,
  },
});

export const { setLeadsDetails, clearLeadsData } = leadsSlice.actions;
export default leadsSlice.reducer;
