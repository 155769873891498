import { asyncFunc } from "../../common/asyncFunc";
import { apiEndPoints } from "../../common/endpoints";
import { setHeaders } from "../../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const CredentialChangeApi = async (data) => {
  const config = {
    method: "put",
    url: url + apiEndPoints.USER.updateCred,
    headers: setHeaders("application/json", true),
    data,
  };

  return await asyncFunc(config);
};
