import { Routes, Route } from "react-router-dom";
import Router from "./router/Router";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setUser } from "./Redux/slices/userSlice";
import { useEffect } from "react";
import { verifyUser } from "./API/Auth/verifyUser";

const App = () => {
  const userData = useSelector((store) => store.tenantAuth.userData);

  const dispatch = useDispatch();

  const fetchData = async () => {
    if (userData && userData.jwtToken) {
      try {
        const res = await verifyUser();
        if (res.success) {
          dispatch(setAuth(true));
          dispatch(setUser({ ...res.userData, jwtToken: userData.jwtToken }));
        } else {
          dispatch(setAuth(false));
          dispatch(setUser(null));
        }
      } catch (err) {
        dispatch(setAuth(false));
        dispatch(setUser(null));
      }
    }
  };
  useEffect(() => {
    if (userData && userData.jwtToken) {
      fetchData();
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/*" element={<Router />} />
      </Routes>
    </>
  );
};

export default App;
