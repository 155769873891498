import React from "react";
import CreateHousehold from "../Pages/AccountPages/CreateHousehold";
import { components } from "react-select";
const ShareProfile = React.lazy(() =>
  import("../components/ShareProfile/ShareProfile")
);
const CancelViewing = React.lazy(() =>
  import("../Pages/Viewing/CancelViewing")
);
const AccountPage = React.lazy(() =>
  import("../Pages/AccountPages/AccountPage")
);
const LeadNotInterestedPage = React.lazy(() =>
  import("../Pages/NotInterested/NotInterested")
);

const Login = React.lazy(() => import("../Pages/Login/Login"));
const ForgotPassword = React.lazy(() =>
  import("../Pages/Login/ForgetPassword")
);
const ResetPassword = React.lazy(() => import("../Pages/Login/ResetPassword"));

const Register = React.lazy(() => import("../Pages/Register/Register"));
const UserLayout = React.lazy(() => import("../Layout/UserLayout"));
const TenantInformation = React.lazy(() =>
  import("../components/TenantInformation/TenantInformation")
);
const HouseHoldInvite = React.lazy(() =>
  import("../Pages/EmailInvite/HouseHoldInvite")
);
const ApplyProperty = React.lazy(() =>
  import("../components/Property/ApplyPoperty")
);

const Offer = React.lazy(() => import("../components/Property/Offer"));
const OfferSubmission = React.lazy(() =>
  import("../components/TenantMobile/TenancyDetails/OfferSubmission")
);
const FeedBack = React.lazy(() => import("../components/Property/Feedback"));
const ThanksForFeedback = React.lazy(() =>
  import("../components/TenantMobile/TenancyDetails/ThanksForFeedback")
);

const ActionRequired = React.lazy(() =>
  import("../components/TenantMobile/TenancyDetails/ActionRequired")
);

const CreateLead = React.lazy(() =>
  import("../components/Authentication/Create/CreateLead")
);
const LeadSuccessful = React.lazy(() =>
  import("../components/Authentication/Create/SuccessLead")
);
const OfferTemplate = React.lazy(() =>
  import("../Pages/AccountPages/OfferTemplatePage")
);
const Terms = React.lazy(() => import("../Pages/Terms"));
const Privacy = React.lazy(() => import("../Pages/Privacy"));
const Cookies = React.lazy(() => import("../Pages/Cookies"));

export const authRoutes = [
  {
    path: "/profile",
    component: AccountPage,
  },{
    path:"/share-profile",
    component:ShareProfile
  },

  {
    path: "/household",
    component: CreateHousehold,
  },
  {
    path: "/make-offer/:id",
    component: Offer,
  },
  {
    path: "/offer-accepted",
    component: OfferSubmission,
  },
  {
    path: "/action-required",
    component: ActionRequired,
  },
  {
    path: "/cancelViewing/:id",
    component: CancelViewing,
  },
];

export const loginRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/register/:type/:data/:leadId",
    component: Register,
  },
  {
    path: "/cancelViewing/:id",
    component: CancelViewing,
  },
];

export const routeWithoutLayout = [
  {
    path: "/lead-form/:id/:isEdit",
    component: CreateLead,
  },
  {
    path: "/lead-form/r/:type/:isEdit",
    component: CreateLead,
  },
  {
    path: "/lead-form/:id",
    component: CreateLead,
  },
  {
    path: "/lead-form/r/:type",
    component: CreateLead,
  },
  {
    path: "/lead-created",
    component: LeadSuccessful,
  },
  {
    path: "/offer-template",
    component: OfferTemplate,
  },
  {
    path: "/cancelViewing/:id",
    component: CancelViewing,
  },
];

export const commonRoutes = [
  {
    path: "/",
    component: UserLayout,
  },
  {
    path: "/notInterested",
    component: LeadNotInterestedPage,
  },
  {
    path: "/tenant-form",
    component: TenantInformation,
  },
  {
    path: "/household-email-invite",
    component: HouseHoldInvite,
  },
  {
    path: "/apply-property/:id",
    component: ApplyProperty,
  },
  {
    path: "/offer-feedback/:id",
    component: FeedBack,
  },
  {
    path: "/feedback-success",
    component: ThanksForFeedback,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/cookies",
    component: Cookies,
  },
  {
    path: "/cancelViewing/:id",
    component: CancelViewing,
  },
];
