import moment from "moment";

export const formatTimestampAgo = (timestamp) => {
  const momentTimestamp = moment(timestamp);
  const now = moment();
  const hoursDiff = now.diff(momentTimestamp, "hours");

  if (hoursDiff < 24) {
    // If the difference is less than 24 hours, display hours
    return momentTimestamp.fromNow();
  } else {
    // If the difference is 24 hours or more, display days
    const daysDiff = now.diff(momentTimestamp, "days");
    return `${daysDiff} ${daysDiff === 1 ? "day" : "days"} ago`;
  }
};

export const formatDateToMDY = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const getGreetingTime = () => {
  const currentHour = new Date().getHours();

  if (currentHour >= 0 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};
