const regx = {
  email: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/),
  number: new RegExp(/^\d*\.?\d*$/),
  emptySpace: new RegExp(/(.|\s)*\S(.|\s)*/),
  phoneNumber: new RegExp(/^[0-9]{10,}$/),
  date: new RegExp(/^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/),
  dobRegex: /^(19|20)\d{2}-\d{2}-\d{2}$/,
  isNumeric: /^[0-9]*$/,
  numberWithCommaSpace: new RegExp(/^\d*(,\d{1,3})*(\.\d*)?$/)
};
export const numberRgx = {
  value: /^[0-9]+$/i,
  message: "Only numbers are allowed",
};

export default regx;
