export const FormErrorMessage = {
  email: "Email cannot be empty",
  emailFormat: "Invalid email",
  password: "Password cannot be empty",
  passwordFormat: "Password must be at least 6 characters long",
  confirmPassword: "Confirm password cannot be empty",
  matchPassword: "Password is not matching",
  imageFormat: "Invalid image format",
  MobileNumber: "Mobile number cannot be empty",
  MobileNumberFormat: "Invalid mobile number",
  householdName: "Nickname can't be empty ",
  selectOption: "Please select the option",
  occupation: "Occupation cannot be empty",
  offer: "Offer cannot be empty",
  offerFormat: "Only numbers allowed",
  tenancyLength: "Tenant Length cannot be empty",
  tenancyLengthFormat: "Only number allowed",
  date: "Date cannot be empty",
  dateFormat: "Invalid date format",
  comments: "Comments cannot be empty",
};

export const CommonErrorMessage = "Field cannot be empty";
