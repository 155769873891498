import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const EditHouseHold = async (data,id) => {
  const config = {
    method: "put",
    url: url + apiEndPoints.USER.editHouseHold.replace(":id", id),
    headers: setHeaders("application/json", true),
    data,
  };

 
  return await asyncFunc(config);
};


