import { createSlice } from "@reduxjs/toolkit";

const namespace = "household";
const initialState = {
  userData: {},
 
};

const HouseHoldSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setHouseHold: (state, action) => {
      state.userData = action.payload;
    },
    clearHouseHold:()=>initialState
  },
});

export const { setHouseHold,clearHouseHold } = HouseHoldSlice.actions;
export default HouseHoldSlice.reducer;
