import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast, { Toaster } from "react-hot-toast";
import { createHouseHold } from "../../API/HouseHold/HouseHold";
import { setHouseHold, clearHouseHold } from "../slices/houseHoldSlice";
import { deleteHousehold } from "../../API/HouseHold/DeleteHouseHold";
import { EditHouseHold } from "../../API/HouseHold/EditHouseHold";
import { Resend } from "../../API/Resend/Resend";
import { GetHouseHold } from "../../API/HouseHold/GetHouseHold";
import { HouseHoldInviteEmail } from "../../API/HouseHold/EmailInvite";
import { ExistsUser } from "../../API/HouseHold/ExistsUser";
import { GetPendingInvite } from "../../API/HouseHold/PendingInvite";
import { setHouseHoldInvite } from "../slices/userSlice";
import { AcceptHouseHoldInvitation } from "../../API/HouseHold/AcceptHouseHoldInvitation";
import { DeclineHouseHoldInvitation } from "../../API/HouseHold/DeclineHouseHoldInvitaion";
import { handleApiError } from "../../helper/helperFunction";
/* Add household */
export const handleAddHouseHold = createAsyncThunk(
  `tenant/add-household`,
  async ({ data, cb, setLoading }, { dispatch, getState }) => {
    try {
      setLoading(true);

      const res = await createHouseHold(data);
      if (res.success) {
        dispatch(setHouseHold(res?.data?.houseHold));

        cb();
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

/* delete individual household */

export const deleteHouseHold = createAsyncThunk(
  `household/delete-user`,
  async ({ cb,setIsDeleting, id }, { dispatch, getState }) => {
    try {
      setIsDeleting(true)
      const res = await deleteHousehold(id);

      if (res.success) {
        dispatch(clearHouseHold());
        cb();
        toast.success("Household deleted successfully");
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    }finally{
      setIsDeleting(false)
    }
  }
);

export const handleEditHouseHold = createAsyncThunk(
  `tenant/edit-household`,
  async ({ data, id, cb, setLoading }, { dispatch, getState }) => {
    try {
      setLoading(true);

      const res = await EditHouseHold(data, id);
      if (res.success) {
        dispatch(setHouseHold(res?.data?.houseHold));
        cb();
        toast.success("Successfully updated",  {duration: 1000});
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleResend = createAsyncThunk(
  `tenant/add-household`,
  async ({ data, cb, setLoading }, { dispatch, getState }) => {
    try {
      setLoading(true);
      const res = await Resend(data);
      if (res.success) {
        cb();
        toast.success("Resend successfully");
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }}
  );
  
  

  export const handelGetHouseHold = createAsyncThunk(
    `agent/add-household`,
    async ({setLoading}, { dispatch, getState }) => {
      try {
        setLoading(true);
        const resp = await GetHouseHold();
        const pendingInviteCheck=await GetPendingInvite();
        if (resp?.success && pendingInviteCheck?.success) {
          dispatch(setHouseHoldInvite(pendingInviteCheck?.data?.houseHold))
          dispatch(setHouseHold(resp?.data?.houseHold))
        }
      } catch (error) {
        console.log(error);
        const message = handleApiError(error);
        // toast.error(message);
      } finally {
        setLoading(false);
      }
    }
  );
  
  export const handleCheckHouseHold = createAsyncThunk(
    `tenant/check-household`,
    async ({ data, cb,cb_1, setLoading ,errCB}, { dispatch, getState }) => {
      try {
        setLoading(true);
        const res = await ExistsUser(data);
        if (res?.success) {
          if (res?.data?.isFound && !res?.data?.isAlreadyInHouseHold) {
            cb_1(res?.data?.name,res?.data?.profilePic);
          } else if (!res?.data?.isFound) {
            cb();
          } else if (res?.data?.isFound && res?.data?.isAlreadyInHouseHold) {
         
            errCB()
          }
        }
      } catch (error) {
        console.log(error);
        const message = handleApiError(error);
        toast.error(message);
      } finally {
        setLoading(false);
      }}
    );



export const handleAcceptInvitation=createAsyncThunk(
  `tenant/accept-household-Invitation`,
  async ({data,setLoading,cb}, { dispatch, getState }) => {
    try {
     
      setLoading(true);
      const resp = await AcceptHouseHoldInvitation(data);
      const pendingInviteCheck=await GetPendingInvite();
      const respo = await GetHouseHold(); 
      if(resp?.success && pendingInviteCheck?.success){
        dispatch(setHouseHoldInvite(pendingInviteCheck?.data?.houseHold))
        dispatch(setHouseHold(respo?.data?.houseHold))
        cb()
      }
      
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);

export const handleDeclineInvitation=createAsyncThunk(
  `tenant/decline-household-Invitation`,
  async ({data,setLoading,cb}, { dispatch, getState }) => {
    try {
      setLoading(true);

      const resp = await DeclineHouseHoldInvitation(data);
      const pendingInviteCheck=await GetPendingInvite();
      const respo = await GetHouseHold(); 
      if(resp?.success && pendingInviteCheck?.success){
        dispatch(setHouseHoldInvite(pendingInviteCheck?.data?.houseHold))
        dispatch(setHouseHold(respo?.data?.houseHold))
        cb()
      }
      
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }
);