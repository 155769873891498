import Tenant from "./Tenant";
import { trimURL as trimName } from "../../helper/helperFunction";
import TenantUser from "./TenantUser";
const AddHouseHold = ({
  onClick,
  fields,
  error,
  userData,
  houseHold_id,
  onDelete,
  houseHold,
}) => {
  return (
    <>
      <div className="d-flex gap-3 mb-3 flex-wrap">
        <TenantUser label={"You"} img={userData?.profilePic} />
        {fields &&
          fields?.tenants &&
          fields?.tenants
            .filter(
              (ten) => ten.email.toLowerCase() !== userData?.email.toLowerCase()
            )
            .map((ten, index) => (
              <>
                {ten.email ? (
                  <Tenant
                    key={index}
                    label={
                      fields && ten?.name
                        ? trimName(ten?.name, 10)
                        : trimName(ten?.email, 10)
                        ? trimName(ten?.email, 10) 
                        : `Tenant ${index + 2}`
                    }
                    houseHold_id={houseHold_id}
                    onDelete={onDelete}
                    tenId={ten.email}
                    fields={fields}
                    proImg={ten?.profilePic}
                    index={index}
                  />
                ) : (
                  <div className="text-center text-gray-v2 w-fit fw-500 cursor-pointer">
                    <button
                      onClick={() => onClick(houseHold_id ? index + 1 : index)}
                      type="button"
                      className="imgWrapV3 border-1 border-dashed-gray rounded-circle mb-2 d-flex justify-content-center align-items-center bg-transparent"
                      // disabled={
                      //   fields?.tenants?.filter((ten) => ten.email !== userData?.email)
                      //     .length ===
                      //   fields.memberCount - 1
                      // }
                    >
                      <span className="fsr-20 lh-1">+</span>
                    </button>
                    <span>{`Tenant ${index + 2}`}</span>
                  </div>
                )}
              </>
            ))}
      </div>
      {error?.household && (
        <div className="errorMsgV1 ms-3 visible">
          {error?.household?.message}
        </div>
      )}
    </>
  );
};
export default AddHouseHold;
