import { Button, Form, Modal } from "react-bootstrap";

const ModalComponent = ({ children, ...props }) => {
  //reuseable modal

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
