import { asyncFunc } from "../../common/asyncFunc";
import { apiEndPoints } from "../../common/endpoints";
import { setHeaders } from "../../common/setHeader";
import store from "../../../Redux/store";
const url = import.meta.env.VITE__APP_API;

export const ProfileUpdate = async(data) => {
  const jwtToken = store.getState().createAcc?.userData?.jwtToken;
  const config = {
    method: "post",
    url: url + apiEndPoints.USER.profileComplete,
    data,
    headers: setHeaders("application/json", true,jwtToken),
  };
  
  return await asyncFunc(config);
};
