import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";

const url = import.meta.env.VITE__APP_API;

export const DeclineHouseHoldInvitation = (data) => {
  const config = {
    method: "post",
    url: url + apiEndPoints.USER.declineHouseHoldInvitation,
    headers: setHeaders("application/json", true),
    data
  };
  
  return asyncFunc(config);
};
