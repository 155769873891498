import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import AddHousematesModal from "../../Pages/ViewAccModals/AddHousematesModal";
import HouseHoldForm from "./HouseHoldForm";
import { useForm } from "react-hook-form";
import { customFormError } from "../../helper/helperFunction";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import { householdValidation } from "../../Config/config";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  handleAddHouseHold,
  handleEditHouseHold,
} from "../../Redux/actions/household";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import { validFieldEmailFilled } from "../../helper/helperFunction";
import DeleteModal from "../../components/DeleteModal";
import toast from "react-hot-toast";
const CreateHousehold = () => {
  const [modalShow, setModalShow] = useState(false);
  const [houseHoldIndex, setHouseHoldIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hH_id, sethH_id] = useState(null);
  const location = useLocation();
  const isOfferLinkActive = location.state?.offerLinkActive;
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get('property_id'); //
  const userData = useSelector((store) => store?.tenantAuth?.userData);
  const houseHold = useSelector((store) => store?.houseHold?.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    formState: { errors },
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: houseHold && houseHold?._id ? houseHold?.name : "",
      memberCount: houseHold && houseHold?._id ? houseHold?.memberCount : 2,
      relation: houseHold && houseHold?._id ? houseHold?.relation : "",
      tenants: houseHold
        ? houseHold?._id
          ? houseHold?.userData
          : [{ email: "" }]
        : [{ email: "" }],
    },
  });
  const defaultValues = getValues();
  const {
    handleSubmit: handleSubmitGmail,
    register: registerGmail,
    setError: setEmailError,
    formState: { errors: mailError },
    reset: resetMail,
  } = useForm();

  // reason for filtering=> from the api i got the current tenant id and email in the Tenant array. but i already have current tenant data on redux.
  const filteredTenant = defaultValues?.tenants?.filter(
    (ten) => ten?.email.toLowerCase() !== userData?.email.toLowerCase()
  );
  const houseHold_id = houseHold?._id;

  const handleDeleteClick = (id) => {
    if (defaultValues.tenants.length === 2 && houseHold_id) {
      // error message:
      toast.error("Cannot delete the household if there are only two tenants present")
      return
    };
    setShowDeleteModal(true);
    sethH_id(id);
  };
  //delete handler ::::
  const onClickDelete = () => {
    if (defaultValues.tenants.length === 2 && houseHold_id) {
      // error message:
      toast.error("Cannot delete the household if there are only two tenants present")
      return
    };

    const data = defaultValues.tenants.filter(
      (list, index) => list?.email.toLowerCase() !== hH_id.toLowerCase()
    );

    if (houseHold_id) {
      // User confirmed, call your delete function here
      //deleting the user by email:
      setValue("tenants", data, { shouldValidate: true });
      setValue("memberCount", defaultValues.tenants.length - 1, {
        shouldValidate: true,
      });
    } else if (!houseHold_id) {
      if (defaultValues.tenants.length === 1) {
        setValue("tenants", [...data, { email: "" }], {
          shouldValidate: true,
        });
        setValue("memberCount", 2, { shouldValidate: true });
      } else {
        setValue("tenants", data, { shouldValidate: true });
        defaultValues.tenants.length === 2
          ? setValue("memberCount", 2, { shouldValidate: true })
          : setValue("memberCount", defaultValues.tenants.length, {
            shouldValidate: true,
          });
      }
    }
    setShowDeleteModal(false);
  };
  const cb = () => {
    // if offer link active are navigating make-offer page
    if (isOfferLinkActive && !houseHold_id) {
      navigate("/action-required", { replace: true, state: { id: propertyId } });
    } else if (isOfferLinkActive && houseHold_id) {
      navigate(`/make-offer/${isOfferLinkActive}`);
    } else {
      navigate("/profile");
    }
  };
  useEffect(() => {
    if (defaultValues.tenants === undefined) {
      setValue("tenants", houseHold?.userData, { shouldValidate: true });
    }
  }, [houseHold]);
  const onSubmit = (data) => {
    const isValid = householdValidation(
      filteredTenant,
      defaultValues,
      setError
    );
    if (!isValid)
      return customFormError(setError, "household", "Please add the household");

    if (!data?.relation)
      return customFormError(
        setError,
        "relation",
        FormErrorMessage?.selectOption
      );

    if (houseHold_id) {
      //if there is household id which means the tenant has already added a household
      let obj = { data, id: houseHold_id, setLoading, cb };
      dispatch(handleEditHouseHold(obj));
    } else {
      // this dispatch is for creating household
      let obj = { data, setLoading, cb };
      // alert("submitted")
      dispatch(handleAddHouseHold(obj));
    }
  };

  const handleAddClick = () => {
    const { tenants } = defaultValues;
    setValue("memberCount", defaultValues?.memberCount + 1, {
      shouldValidate: true,
    });
    const currentTenants = getValues("tenants");
    const newEmailKey = "email";
    setValue("tenants", [...currentTenants, { [newEmailKey]: "" }], {
      shouldValidate: true,
    });
  };

  const handleAddMail = (id) => {
    const isFilled = validFieldEmailFilled(defaultValues.tenants, id);
    if (isFilled === false) return;
    setHouseHoldIndex(id);
    setModalShow(true);
  };

  const handleRemoveClick = () => {
    // if(houseHold_id) return;
    const currentTenants = getValues("tenants");

    // Check if the last element has an empty string for the 'email' key
    const lastElement = currentTenants[currentTenants.length - 1];
    const lastEmailValue = lastElement && lastElement.email;
    if (lastEmailValue === "" && houseHold_id) {
      // If the last element has an empty string, perform the pop operation
      currentTenants.pop();
      setValue("tenants", currentTenants, { shouldValidate: true });
      setValue("memberCount", defaultValues?.memberCount - 1, {
        shouldValidate: true,
      });
      clearErrors("household");
    } else if (!houseHold_id) {
      currentTenants.pop();
      setValue("tenants", currentTenants, { shouldValidate: true });
      setValue("memberCount", defaultValues?.memberCount - 1, {
        shouldValidate: true,
      });
      clearErrors("household");
    }
  };

  if (defaultValues.tenants === undefined) return <LoadingSpinner />;

  return (
    <>
      <div className="mainRegister mt-70">
        <Header />
        <HouseHoldForm
          onClick={handleAddMail}
          register={register}
          defaultValues={defaultValues}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          handleSubmit={handleSubmit}
          errors={errors}
          fields={defaultValues}
          onSubmit={onSubmit}
          userData={userData}
          loading={loading}
          houseHold_id={houseHold_id}
          houseHold={houseHold}
          onDelete={handleDeleteClick}
          handleAddClick={handleAddClick}
          handleRemoveClick={handleRemoveClick}
          isOfferLinkActive={isOfferLinkActive}
        />
        <AddHousematesModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          registerGmail={registerGmail}
          handleSubmitGmail={handleSubmitGmail}
          setValue={setValue}
          defaultValues={defaultValues}
          setError={setEmailError}
          errors={mailError}
          reset={resetMail}
          clearErrors={clearErrors}
          houseHoldIndex={houseHoldIndex}
          userData={userData}
        />
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onClickDelete={onClickDelete}        
      />
    </>
  );
};

export default CreateHousehold;
