import toast from "react-hot-toast";
import { STATUS } from "../Config/config";
import { handleFetchLeadDetails } from "../Redux/actions/leads";
import imagePack from "../staticfiles/imagePack";
import { formatDateToMDY, getGreetingTime } from "./dateFunctions";
import { getOwnershipDescription } from "./greetingFunction";
const ProfilePath = import.meta.env.VITE__APP_PROFILE_PATH;

export const handleApiError = (err) => {
  console.log(err.message);
  let message = "";
  if (err.response) {
    if (err.response.data.errors) {
      // If the API response contains an 'errors' array
      const error = err.response.data.errors[0];
      const message = error.msg || "An error occurred"; // Use the error message or a generic message
      return message;
    } else if (err.response.data.message) {
      message = err.response.data.message;
      return message;
    } else if (err.response.status == 401) {
      message = "Your session has expired. Please log in again";
      return message;
    } else {
      message = err.message;
      return message;
    }
  } else {
    return err;
  }
};

export const isTabAllowed = (selectedTab, isCompletedForm) => {
  if (selectedTab === "personal" && isCompletedForm.personal) {
    return true;
  } else if (selectedTab === "personal" && isCompletedForm.navigatePersonal) {
    return true;
  }

  if (selectedTab === "financial" && isCompletedForm.financial) {
    return true;
  } else if (selectedTab === "financial" && isCompletedForm.navigateFinancial) {
    return true;
  }

  if (selectedTab === "credit" && isCompletedForm.credit) {
    return true;
  } else if (selectedTab === "credit" && isCompletedForm.navigateCredit) {
    return true;
  }
  if (selectedTab === "extra" && isCompletedForm.extra) {
    return true;
  } else if (
    selectedTab === "extra" &&
    isCompletedForm.navigateExtraInformation
  ) {
    return true;
  }
  if (selectedTab === "guarantor" && isCompletedForm.guarantor) {
    return true;
  } else if (selectedTab === "guarantor" && isCompletedForm.navigateGuarantor) {
    return true;
  }
  return false;
};

export const customFormError = (setError, fieldName, errorMessage) => {
  setError(fieldName, {
    type: "manual",
    message: errorMessage,
  });
};

export const trimURL = (url, maxLength) => {
  return url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
};

export const getEnvPath = (type, fileName) => {
  switch (type) {
    case "profilePic":
      return fileName ? ProfilePath + fileName : imagePack.blankProfile;
    case "compressed-profilePic":
      return fileName
        ? ProfilePath + `${fileName.split(".")[0]}-compressed.jpeg`
        : imagePack.blankProfile;

    default:
      return imagePack.blankProfile;
  }
};

export const isDuplicateMail = (tenants, data, userData) => {
  const tenantsData = [...tenants, { email: userData.email }];
  const isFound = tenantsData?.some((tenant) => tenant.email === data?.email);
  console.log("isFound", isFound);

  return isFound;
};

export const validFieldEmailFilled = (tenant, id) => {
  console.log(tenant);
  console.log(id);

  let isValid = true;

  tenant.forEach((li, index) => {
    if (index === id - 1 && li.email === "") {
      isValid = false;
    }
  });

  return isValid;
};

export const generateEmailBody = (values) => {
  const {
    selectedOption,
    link,
    houseHoldName,
    offer: defaultOffer,
    tenancyLength: defaultTenancyLength,
    startDate: defaultStartDate,
    comments: defaultComments,
  } = values;
  const household = houseHoldName;
  const offer = `${
    defaultOffer ? `${defaultOffer} per calendar month` : "X per calendar month"
  }`;
  const tenancyLength = `${
    defaultTenancyLength ? `${defaultTenancyLength}` : "X"
  }`;
  const length = `${
    tenancyLength == 1 ? "1 month" : tenancyLength + " months"
  }`;
  const startDate = `${defaultStartDate ? `${defaultStartDate}` : "X"}`;
  const additionalComments = `${defaultComments ? `${defaultComments}` : "X"}`;
  const rentlyPassLink = link;
  const ownerShipName = getOwnershipDescription(selectedOption);
  return `
    Good ${getGreetingTime()},
    
    I would like to submit an offer. Please see my details below:
    
    Offer: ${offer}
    Length of tenancy: ${length}
    Ideal start date: ${formatDateToMDY(startDate)}
    Additional comments: ${additionalComments}
    
    Here is ${ownerShipName} RentlyPass where you can view all ${ownerShipName} details: ${rentlyPassLink}

    
    Thanks
  `;
};
/*   // Household: ${household} */
export const getEnvPathAdv = (type, fileName) => {
  console.log(type);
  console.log(fileName);

  switch (type) {
    case "Adv-pic":
      return fileName ? ProfilePath + fileName : null;
    case "compressed-Adv-pic":
      return fileName
        ? `${ProfilePath}${fileName.split(".")[0]}-compressed.jpeg`
        : null;

    default:
      return null;
  }
};

export function extractStartDate(dateString) {
  if (!dateString) return;
  const dateParts = dateString?.split("T");
  return dateParts[0];
}
export function capitalizeSentence(sentence) {
  return sentence.replace(
    /(^|\.\s+|\n)([a-z])/g,
    (match, p1, p2) => p1 + p2.toUpperCase()
  );
}

export function formatNumber(number) {
  if (number < 1000) {
    return number;
  } else if (number < 10000) {
    return (number / 1000) * 1000;
  } else {
    return new Intl.NumberFormat().format(number);
  }
}

// eslint-disable-next-line react-refresh/only-export-components
export const emptyFunction = () => {};

export const RedirectLeadsfrom = (
  dispatch,
  leadsData,
  navigate,
  userData,
  id
) => {
  const fetchLeadDetails = (id) => {
    dispatch(handleFetchLeadDetails({ id }));
  };
  fetchLeadDetails(id);
  if (leadsData) {
    if (!userData) {
      navigate("/register");
    } else {
      if (leadsData?.user?._id !== userData?._id) {
        toast.error("Login to invited email");
        // navigate("/profile");
      }
    }
  }
};

export const GetStatus = (id) => {
  if (id) return STATUS?.COMPLETED;
  return "-";
};


export const createSearchParams = (obj) => {
  if (!obj) return "";
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    // if the value is array ;
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else if (value !== undefined && value !== null) {
      params.append(key, value);
    }
  }

  return `?${params.toString()}`;
}