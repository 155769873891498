import { Button, Dropdown, Form } from "react-bootstrap";
import { HOUSEHOLD_RELATION_LIST } from "../../Config/config";
import Retired from "../../components/TenantInformation/tabs/Financial/Status/Retired";

const HouseHoldDropDown = ({
  defaultValue,
  setValue,
  error,
  clearError,
  isOfferLinkActive,
}) => {
  const handelSelectDrop = (list) => {
    setValue("relation", list, { shouldValidate: true });
    clearError("relation");
  };
  return (
    <>
      <Dropdown className="w-100 mb-3">
        <Dropdown.Toggle
          variant="outline-secondary"
          className="w-100 d-flex justify-content-between align-items-center fsr-14 px-20px"
          id="dropdown-basic"
        >
          {defaultValue && defaultValue
            ? defaultValue
            : isOfferLinkActive
            ? "Tenant’s relationship"
            : "Your relationship"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {HOUSEHOLD_RELATION_LIST.map((list) => (
            <Dropdown.Item
              key={list?.key}
              disabled={list?.relation === defaultValue}
              onClick={() => handelSelectDrop(list?.relation)}
            >
              {list?.relation}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {error && <div className="errorMsgV1 ms-3 visible">{error?.message}</div>}
    </>
  );
};

export default HouseHoldDropDown;
