import { createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import {
  capitalizeSentence,
  emptyFunction,
  handleApiError,
} from "../../helper/helperFunction";

/**
 * A utility function to create an async thunk with centralized error handling.
 *
 * @param {string} type - The action type string used to identify the thunk.
 * @param {function} asyncFunction - The asynchronous function to be executed. It receives `args` and `thunkAPI` as parameters.
 * @param {function} [errorCallBack] - An optional callback function to be executed in case of an error.
 *
 * @returns {function} - Returns a thunk action creator function that can be dispatched.
 *
 * This function uses `createAsyncThunk` from Redux Toolkit to create an async thunk.
 * It wraps the provided `asyncFunction` in a `try-catch` block to handle errors centrally.
 * If an error occurs during the execution of `asyncFunction`, it:
 * 1. Handles the error using `handleApiError` to get a user-friendly message.
 * 2. Displays the error message using `toast.error`.
 * 3. Executes the optional `errorCallBack` function if provided.
 */

export const createThunk = (
  type,
  asyncFunction,
  errorCallBack = emptyFunction
) => {
  return createAsyncThunk(type, async (args, thunkAPI) => {
    try {
      const result = await asyncFunction(args, thunkAPI);
      return { data: result }; // Return a plain object with the result
    } catch (error) {
      console.log(error, "error");
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
      errorCallBack(args, thunkAPI);
      return thunkAPI.rejectWithValue(message);
    }
  });
};
