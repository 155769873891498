import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";

const url = import.meta.env.VITE__APP_API;

export const ExistsUser = async(data) => {
  
  const config = {
    method: "get",
    url: url + apiEndPoints.USER.checkUserExists.replace(":email", data?.email),
    headers: setHeaders("application/json", true),
    
  };
  return await asyncFunc(config);
};
