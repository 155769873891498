import { Navigate } from "react-router-dom";

function ProtectedRouteTwo({ role, children, isOfferLinkActive, path }) {
  const isTenant = role === "Tenant";
  const hasLeadIdInPath = path?.includes("leadId");
  if (isTenant) {
    if (hasLeadIdInPath) {
      return <Navigate to="/lead-created" replace />;
    }
    if (isOfferLinkActive) {
      return <Navigate to={`/make-offer/${isOfferLinkActive}`} replace />;
    }
    return <Navigate to="/profile" replace />;
  }

  return children;
}

export default ProtectedRouteTwo;
