import { asyncFunc } from "../../common/asyncFunc";
import { apiEndPoints } from "../../common/endpoints";
import { setHeaders } from "../../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const changePasswordApi = async (data, jwt) => {
  const config = {
    method: "post",
    url: url + apiEndPoints.USER.changePassword,
    headers: setHeaders("application/json", false, jwt),
    data,
  };

  return await asyncFunc(config);
};
