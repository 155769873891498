import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Register as RegisterApi } from "../../API/Auth/Register/Register";
import { toast } from "react-toastify";
import { handleApiError } from "../../helper/helperFunction";

const namespace = "tenant";

const initialState = {
  userData: null,
  
};


const createAccSlice = createSlice({
  name: namespace,
  initialState,

  reducers: {
    setAcc:(state,action)=>{
      state.userData=action.payload
    }
    ,
    clearData:()=>initialState
  },

});



export const { setAcc,clearData } = createAccSlice.actions;
export default createAccSlice.reducer;
