import InputEditElement from "./inputElem";
import { Button } from "react-bootstrap";
const PasswordOperation = ({
  registerPassword,
  onSubmitEditPassword,
  errorsPassword,
  loading,
  watch,
  handleSubmitPassword,
  setEditShow,
  showEdit,
}) => {
  return (
    <div className="d-flex flex-column gap-3">
      {!showEdit.isEditPassword ? (
        <div className="position-relative inputV1">
          <InputEditElement
            type="password"
            placeholder={"Update your password"}
            className="h-100"
            disabled={!showEdit?.isEditPhone}

          />
          <Button
            variant="dark"
            className="position-absolute end-0 top-0 h-100 fw-700 px-3 min-w-100px"
            onClick={
              showEdit.isEditPassword
                ? handleSubmitPassword(onSubmitEditPassword)
                : () =>
                    setEditShow({
                      ...showEdit,
                      isEditPassword: !showEdit.isEditPassword,
                    })
            }
            disabled={loading}
          >
            {showEdit.isEditPassword ? "UPDATE" : "EDIT"}
          </Button>
        </div>
      ) : (
        <>
          <div className="position-relative inputV1">
            <InputEditElement
              type="password"
              placeholder="Current password"
              className="h-100"
              register={registerPassword}
              name={"previous"}
              id={"previous"}
              validationSchema={{
                required: {
                  value: true,
                  message: "Password cannot be empty",
                },
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
              errors={errorsPassword?.previous}
            />
            <Button
              variant="dark"
              className="position-absolute end-0 top-0 h-100 fw-700 px-3 min-w-100px"
              onClick={
                showEdit.isEditPassword
                  ? handleSubmitPassword(onSubmitEditPassword)
                  : () =>
                      setEditShow({
                        ...showEdit,
                        isEditPassword: !showEdit.isEditPassword,
                      })
              }
              disabled={loading}
            >
              {showEdit.isEditPassword ? "UPDATE" : "EDIT"}
            </Button>
          </div>

          <div className="position-relative inputV1">
            <InputEditElement
              type="password"
              placeholder="New password"
              className="h-100"
              register={registerPassword}
              id={"new-password"}
              name={"current"}
              validationSchema={{
                required: {
                  value: true,
                  message: "Password cannot be empty",
                },
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
              errors={errorsPassword?.current}
            />
          </div>

          <div className="position-relative inputV1">
            <InputEditElement
              type="password"
              placeholder={"Confirm password"}
              className="h-100"
              register={registerPassword}
              name={"confirmPassword"}
              id={"confirm-password"}
              validationSchema={{
                required: {
                  value: true,
                  message: "Password cannot be empty",
                },
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
                validate: (value) =>
                  watch("current") === value || "Password is not matching",
              }}
              errors={errorsPassword?.confirmPassword}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PasswordOperation;
