import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const deleteHousehold = async (id) => {
  const config = {
    method: "delete",
    url: url + apiEndPoints.USER.deleteHouseHold.replace(":id", id),
    headers: setHeaders("application/json", true),
  };
    console.log(config)
  return await asyncFunc(config);
};
