import { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { EDIT_DETAILS } from "../../Config/config";
import {
  deleteUser,
  handleCredentialsChange,
  handleUpdatePassword,
  logOutUser,
  uploadUserProfile,
} from "../../Redux/actions/tenantAuth";
import DeleteModal from "../../components/DeleteModal";
import { getEnvPath } from "../../helper/helperFunction";
import MailOperation from "./MailOperaion";
import PasswordOperation from "./PasswordOperation";
import PhoneOperation from "./PhoneOperation";
const EditProfileModal = (props) => {
  const [showEdit, setEditShow] = useState(EDIT_DETAILS);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    handleSubmit: handleSubmitGmail,
    register: registerGmail,
    formState: { errors: errorsGmail },
  } = useForm();
  const {
    handleSubmit: handleSubmitPhone,
    register: registerPhone,
    formState: { errors: errorsPhone },
  } = useForm();
  const {
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    formState: { errors: errorsPassword },
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const userData = props?.userData;
  const cb = () => {
    props?.onHide();
  };
  let obj = { setLoading, cb };

  const onSubmitEditGmail = (data) => {
    //dispatching the email
    // if the user press the submit button and without making any single change=> WON'T SEND TO BACKEND
    if (data?.email === undefined) {
      props.onHide();
      //set to previous state
      setEditShow(EDIT_DETAILS);
      return;
    }
    obj = { ...obj, data };

    dispatch(handleCredentialsChange(obj));
  };

  const handleDeleteClickPopUp = () => {
    setShowDeleteModal(true);
  };
  const onClickDelete = () => dispatch(deleteUser());
  const onSubmitEditPhone = (data) => {
    //dispatching the phone
    // if the user press the submit button and without making any single change=> WON'T SEND TO BACKEND
    if (data?.phone === undefined) {
      props.onHide();
      //set to previous state
      setEditShow(EDIT_DETAILS);
      return;
    }

    obj = { ...obj, data };
    dispatch(handleCredentialsChange(obj));
  };
  const onSubmitEditPassword = ({ confirmPassword, ...data }) => {
    obj = { ...obj, data };

    dispatch(handleUpdatePassword(obj));
  };
  const handleLogout = () => dispatch(logOutUser());

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    if (file) {
      // You can set the selected file in state if you need to use it later
      dispatch(uploadUserProfile({ file, setLoading, cb }));
    }
  };
  return (
    <>
      <Form>
        <div className="py-3 d-flex flex-column gap-3">
          <div className="d-flex gap-3 mb-4">
            <div className="position-relative text-center">
              <div className="imgWrapV2">
                <img
                  src={getEnvPath(
                    "compressed-profilePic",
                    userData?.profilePic
                  )}
                  alt="profile"
                  className="roundedImgDefault"
                />
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                className="top-0 end-0 start-0 bottom-0 position-absolute opacity-0 cursor-pointer"
                accept="image/*"
                disabled={loading}
              />

              <span
                href="#"
                className="fsr-14 fw-500 pointer-events-none text-decoration-underline text-primary"
              >
                Edit
              </span>
            </div>
            <div className="mt-1">
              <p className="mb-0 fsr-14 fw-500 text-gray-v2">Full name</p>
              <p className="mb-0 fsr-24 fw-700">
                {userData?.tenantPersonalDetails?.name ?? userData?.email}
              </p>
            </div>
          </div>
          <MailOperation
            registerGmail={registerGmail}
            errorsGmail={errorsGmail}
            handleSubmitGmail={handleSubmitGmail}
            loading={loading}
            onSubmitEditGmail={onSubmitEditGmail}
            setEditShow={setEditShow}
            userData={userData}
            showEdit={showEdit}
          />
          <PhoneOperation
            errorsPhone={errorsPhone}
            handleSubmitGmail={handleSubmitPhone}
            loading={loading}
            onSubmitEditPhone={onSubmitEditPhone}
            registerPhone={registerPhone}
            setEditShow={setEditShow}
            showEdit={showEdit}
            userData={userData}
          />
          <PasswordOperation
            onSubmitEditPassword={onSubmitEditPassword}
            errorsPassword={errorsPassword}
            handleSubmitPassword={handleSubmitPassword}
            registerPassword={registerPassword}
            watch={watch}
            showEdit={showEdit}
            setEditShow={setEditShow}
            loading={loading}
          />
          <div className="d-flex justify-content-between">
            <div className="text-center" onClick={handleDeleteClickPopUp}>
              <a href="#" className="text-gray-v2 fsr-14 fw-500 deleteBtn">
                Delete your account forever
              </a>
            </div>
            <div className="text-center" onClick={handleLogout}>
              <a className="text-gray-v2 fsr-14 fw-500 cursor-pointer hoverBtn">
                Logout
              </a>
            </div>
          </div>
        </div>
      </Form>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onClickDelete={onClickDelete}
      />
    </>
  );
};

export default EditProfileModal;
