import InputEditElement from "./inputElem"
import regx from "../../staticfiles/svgPack/regex"
import { Button } from "react-bootstrap"
const PhoneOperation=({userData,showEdit,setEditShow,errorsPhone,handleSubmitGmail,registerPhone,onSubmitEditPhone,loading})=>{


    return(
        <div className="position-relative inputV1">
        <InputEditElement
          type="text"
          placeholder="phone number"
          defaultValue={userData?.phone}
          disabled={!showEdit?.isEditPhone}
          id={"phone-number"}
          register={registerPhone}
          errors={errorsPhone?.phone}
          name={"phone"}
          validationSchema={{
            pattern: {
              value: regx.phoneNumber,
              message: "Invalid phone number",
            },
          }}
        />
        <Button
          variant="dark"
          className="position-absolute end-0 top-0 h-100 fw-700 px-3 min-w-100px"
          onClick={
            showEdit.isEditPhone
              ? handleSubmitGmail(onSubmitEditPhone)
              : () =>
                  setEditShow({
                    ...showEdit,
                    isEditPhone: !showEdit.isEditPhone,
                  })
          }
          disabled={loading}
        >
          {showEdit.isEditPhone ? "UPDATE" : "EDIT"}
        </Button>
      </div>
    )
}

export default PhoneOperation;