import { createSlice } from "@reduxjs/toolkit";

const namespace = "property";
const initialState = {
  propertyData: null,
  propertyOfferData: null,
  isOfferAcceptedNavigationActive: false,
  isNotOfferAcceptedNavigationActive: false,
  propertyId: null,
};

const PropertySlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setProperty: (state, action) => {
      state.propertyData = action.payload;
    },
    setOfferAcceptNavigation: (state, action) => {
      state.isOfferAcceptedNavigationActive = action.payload;
    },
    setNotOfferAcceptNavigation: (state, action) => {
      state.isNotOfferAcceptedNavigationActive = action.payload;
    },
    setPropertyOffer: (state, action) => {
      state.propertyOfferData = action.payload;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    clearProperty: () => initialState,
  },
});

export const {
  setProperty,
  setOfferAcceptNavigation,
  setNotOfferAcceptNavigation,
  clearProperty,
  setPropertyOffer,
  setPropertyId,
} = PropertySlice.actions;
export default PropertySlice.reducer;
