import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";

const url = import.meta.env.VITE__APP_API;

export const GetAd = async (type) => {
  const fullUrl = `${url}${apiEndPoints.USER.getAd}?${
    type ? `&type=${type}` : ""
  }`;
  const config = {
    method: "get",
    url: fullUrl,
    headers: setHeaders("application/json", true),
  };

  return await asyncFunc(config);
};
