import { createSlice } from "@reduxjs/toolkit";

const namespace = "tenant";
const initialState = {
  userData: null,
  isLogged: false,
  houseHoldInvite: [],
  offerTemplateData: null,
  adData: null,
  hasSeenPopUp: false,
};

const tenantSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setAuth: (state, action) => {
      state.isLogged = action.payload;
    },
    clearAuth: () => initialState,
    setOfferTemplate: (state, action) => {
      state.offerTemplateData = action.payload;
    },
    setHouseHoldInvite: (state, action) => {
      state.houseHoldInvite = action.payload;
    },
    setSeenHouseHoldInvite: (state, action) => {
      state.hasSeenPopUp = action.payload;
    },
    setAd: (state, action) => {
      state.adData = action.payload;
    },
  },
});

export const {
  setAuth,
  clearAuth,
  setUser,
  setHouseHoldInvite,
  setOfferTemplate,
  setAd,
  setSeenHouseHoldInvite,
} = tenantSlice.actions;
export default tenantSlice.reducer;
