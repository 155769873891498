import blankProfile from "../../public/img/BlankImage.png";
import rentlyLogo from "../../public/rently-logo.svg";

import avatarV1 from "./source/Avatar-v1.png";

const imagePack = {
  blankProfile,

  rentlyLogo,
};

export default imagePack;
