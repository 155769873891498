import { Button, Dropdown, Form } from "react-bootstrap";
import HouseHoldDropDown from "./HouseHoldDropDown";
import AddHouseHold from "./AddHouseHold,";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import { DISABLE_MEMBER_COUNT } from "../../Config/config";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { deleteHouseHold } from "../../Redux/actions/household";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeleteModal from "../../components/DeleteModal";
const HouseHoldForm = ({
  onClick,
  register,
  setValue,
  setError,
  clearErrors,
  handleSubmit,
  errors,
  onSubmit,
  userData,
  onDelete,
  defaultValues,
  handleAddClick,
  loading,
  houseHold_id,
  handleRemoveClick,
  houseHold,
  fields,
  isOfferLinkActive,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting,setIsDeleting] = useState(false)
  const cb = () => navigate("/profile", { replace: true });
  let obj = { cb, id: houseHold_id , setIsDeleting};

  const handleDeleteClickPopUp = () => {
    setShowDeleteModal(true);
  };
  const onClickDelete = () => dispatch(deleteHouseHold(obj));

  return (
    <>
      <div className="formV1Wrapper pt-5 px-3">
        <Form className="formV1" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-center">
            <div className="d-flex align-items-center mb-2 position-relative">
              <span
                role="button"
                onClick={() => navigate(-1)}
                className="position-absolute start-0"
              >
                <img src="/img/backArrowV2.svg" alt="back" />
              </span>
              <h2 className="fsr-24 fw-700 mb-0 w-100 text-center">
                {houseHold_id ? "Edit your household" : "Create your household"}
              </h2>
            </div>
            <p className="fsr-14 text-gray fw-500 mb-0">
              We'll create a URL to share all your details in one-click.
            </p>
          </div>

          <div className="pt-5">
            <div className="mb-3 form-floating-v1">
              <Form.Floating>
                <Form.Control
                  id="floatingPhoneNumberCustom"
                  type="text"
                  placeholder="Give your household a nickname"
                  {...register("name", {
                    required: {
                      value: true,
                      message: FormErrorMessage?.householdName,
                    },
                  })}
                />
                <label
                  htmlFor="floatingPhoneNumberCustom"
                  className="py-3 px-3"
                >
                  Give your household a nickname
                </label>

                <Button
                  variant="white border-0 px-0"
                  className="basicAddon tooltipWrapperMobile"
                >
                  <img src="/img/infoV1.svg" alt="" />
                  <div className="tooltipContent maxContent">
                    This is for your personal use only
                  </div>
                </Button>
              </Form.Floating>
              {errors?.name && (
                <div className="errorMsgV1 ms-3 visible">
                  {errors?.name?.message}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center ps-20px mb-3">
              <p className="mb-0 text-gray-v2 fw-500">Tenants (aged 18+)</p>

              <div className="boxV1 text-gray-v2 fw-500">
                <Button
                  variant="white"
                  className="py-3 pe-3 ps-4 text-gray-v2 lh-1 fw-500 fsr-20 border-0"
                  size="sm"
                  onClick={handleRemoveClick}
                  disabled={defaultValues?.memberCount == DISABLE_MEMBER_COUNT}
                >
                  -
                </Button>
                <span className="px-1">{defaultValues.memberCount}</span>
                <Button
                  variant="white"
                  className="py-3 ps-3 pe-4 text-gray-v2 lh-1 fw-500 fsr-20 border-0"
                  size="sm"
                  onClick={handleAddClick}
                >
                  +
                </Button>
              </div>
            </div>

            <AddHouseHold
              userData={userData}
              onClick={onClick}
              fields={fields}
              error={errors}
              houseHold_id={houseHold_id}
              onDelete={onDelete}
              houseHold={houseHold}
            />

            <HouseHoldDropDown
              clearError={clearErrors}
              defaultValue={defaultValues?.relation}
              setValue={setValue}
              error={errors?.relation}
              isOfferLinkActive={isOfferLinkActive}
            />

            <Button
              variant="primary"
              className="w-100 fsr-14 fw-700 mb-2"
              size="lg"
              type="submit"
              disabled={loading}
            >
              {houseHold_id
                ? "Update your Household"
                : " Create your household"}
            </Button>

            {houseHold_id && (
              <div className="text-end mb-2" onClick={handleDeleteClickPopUp}>
                <a className="text-gray-v2 fsr-14 fw-500 deleteBtn cursor-pointer">
                  Delete your household
                </a>
              </div>
            )}

            {!houseHold_id && (
              <div className="d-flex fsr-14 align-items-start gap-2 text-gray-v2 fw-500">
                <img src="/img/infoV1.svg" alt="" />
                <p>
                  Remember to tell your housemate(s) to look out for an email
                  from us!
                </p>
              </div>
            )}
          </div>
        </Form>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onClickDelete={onClickDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default HouseHoldForm;
