import { asyncFunc } from "../../common/asyncFunc";
import { apiEndPoints } from "../../common/endpoints";
import { setHeaders } from "../../common/setHeader";
import toast, { Toaster } from 'react-hot-toast';
const url = import.meta.env.VITE__APP_API;

export const uploadFile = async (data, setProgress, id) => {
  const config = {
    method: "post",
    url: url + apiEndPoints.USER.uploadProfilePictureApi,
    headers: setHeaders("multipart/form-data", true),
    data,
    onUploadProgress: function (progressEvent) {
      let { loaded, total } = progressEvent;
      const percentage = Math.round((loaded / total) * 100);

      if (setProgress) setProgress(Math.round((loaded / total) * 100));
      if (id)
      toast.loading(
        `Uploading: ${percentage}%`,
        { id: id } 
         );
    },
  };
  return await asyncFunc(config);
};
