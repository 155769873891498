/* eslint-disable react-refresh/only-export-components */
import moment from "moment";
import { customFormError } from "../helper/helperFunction";

const RATING_PARAGRAPH = [
  {
    key: 0,
    heading: "Absolute lifesaver!",
    paragraph: `
          
                RentlyPass has been an absolute lifesaver in my apartment
                hunt. Creating my rental passport was a breeze, and sharing my
                custom URL with estate agents was quick and hassle-free.
                What's even better? Every estate agent I sent it to
                accepted it without a hitch.
       
        `,
  },
  {
    key: 1,
    heading: "No more repetitive application forms",
    paragraph: `
       
            I can't believe how much time and effort this has saved me. No more reptitive application forms. I set up my rental passport in minutes and shared it with landlords effortlessly. It has to be the quickest and easiest way to apply to properties!
       
        `,
  },
  {
    key: 2,
    heading: "This is a game-changer!",
    paragraph: `
        
            This is a game-changer! It's not just about saving time; it's about making the rental process a breeze. Sharing it with estate agents and landlords was so easy. They all accepted it without hesitation. Searching for my new home felt slightly less like a full time job haha!
            
        `,
  },
];

export const STAR = Array.from({ length: 5 });
export const PASSWORD_INITIAL_TYPE = {
  password: false,
  confirmPassword: false,
};

export const COUNTRY_COURT_JUDGEMENT = {
  hasCCJ: null,
  hasMultipleCCJs: null,
};

export const EXTRA_INFORMATION = {
  livingWithChildren: null,
  doDependentsSmoke: null,
  hasPets: null,
  dependents: [],
  pets: [],
  dependentsWhoSmokes: [],
};

//the pages should be true then after can go to the next step
export const PAGES = {
  personal: false,
  financial: false,
  credit: false,
  extra: false,
  guarantor: false,
  navigatePersonal: false,
  navigateCredit: false,
  navigateExtraInformation: false,
  navigateGuarantor: false,
  navigateFinancial: false,
};
export const DEFAULT_TAB_NAME = "personal";

export const MULTI_FORM_DATA = {
  tenantPersonalDetails: {
    honorific: "Mr",
    name: "",
    dob: "",
    address: "",
    description: "",
    howLong: "",
  },
  tenantFinanceInfo: {
    employmentStatus: "",
    occupation: "",
    company: "",
    salary: null,
    joiningDate: null,
    fullTime: null,
    description: "",
    savingsInfo: "",
  },
  tenantCreditInfo: {
    hasCCJ: false, // by default it is false
    hasMultipleCCJs: false, // by default it is false
  },
  tenantAdditionalInfo: {
    livingWithChildren: null,
    doDependentsSmoke: null,
    dependentsSmoke: [],
    dependents: [],
    hasPets: null,
    pets: [
      {
        name: "",
        breed: "",
        age: 0,
      },
    ],
  },
  tenantGuarantorInfo: {
    hasGuarantor: null,
    honorific: "",
    name: "",
    occupation: "",
    address: "",
    email: "",
    phone: null,
    company: "",
    salary: null,
    savingsInfo: "",
  },
};

export const EDIT_DETAILS = {
  isEditGmail: false,
  isEditPhone: false,
  isEditPassword: false,
};
export default RATING_PARAGRAPH;

export const SAVING_INVESTMENTS_PLACEHOLDER = "Additional savings/investments";
export const FINANCIAL_DROPDOWN_OPTION = [
  { key: "1", label: "Employee" },
  { key: "2", label: "Unemployed" },
  { key: "3", label: "Self-employed" },
  { key: "4", label: "Student" },
  { key: "5", label: "Retired" },
  { key: "6", label: "Director" },
];

export const IS_EDITABLE = {
  personal: true,
  financial: true,
  credit: true,
  extra: true,
  guarantor: true,
  navigatePersonal: true,
  navigateCredit: true,
  navigateExtraInformation: true,
  navigateGuarantor: true,
  navigateFinancial: true,
};

export const STATUS = {
  COMPLETED: "Completed",
  PENDING: "-",
};

export const HOUSEHOLD_RELATION_LIST = [
  { key: 1, relation: "Family" },
  { key: 2, relation: "Friends" },
  { key: 3, relation: "Partner" },
];

export const householdValidation = (data, member, setError) => {
  if (
    data?.length === 0 ||
    data?.length !== member?.memberCount - 1 ||
    data.some((element) => element.email === "")
  )
    return customFormError(setError, "household", "Please add the household");
  return true;
};

export const PROPERTIES_TO_CHECK = [
  "doDependentsSmoke",
  "hasPets",
  "livingWithChildren",
];

export const DISABLE_MEMBER_COUNT = 2;

export const ONLY_ME = "Only me";

// Iterate through the feedback options=> selected by the tenant
export const FEEDBACK = [
  { id: 1, className: "badgeV2", text: "Furnished/unfurnished" },
  { id: 2, className: "badgeV1", text: "Parking" },
  { id: 3, className: "badgeV1", text: "Value for money" },
  { id: 4, className: "badgeV1", text: "Size of bedroom(s)" },
  { id: 5, className: "badgeV1", text: "Location" },
  { id: 6, className: "badgeV1", text: "Living room" },
  { id: 7, className: "badgeV1", text: "Garden" },
  { id: 8, className: "badgeV1", text: "Found somewhere else" },
  { id: 9, className: "badgeV1", text: "Other" },
];

export const OFFER_ACCEPTING = "Accepting Offers";

export const formatTime = (time) => {
  if (time <= 0) {
    return "Expired"; // If time is zero or negative, display "Expired"
  }

  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  // Format the time based on the remaining hours, minutes, and seconds
  let formattedTime = "";
  if (days > 0) {
    formattedTime += `${days}d `;
  }
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  if (seconds > 0) {
    formattedTime += `${seconds}s `;
  }

  return formattedTime.trim();
};

export const SCHEDULE_DATA = [
  {
    day: "Monday",
    amSelected: true,
    pmSelected: false,
    eveningSelected: false,
  },
  {
    day: "Tuesday",
    amSelected: false,
    pmSelected: false,
    eveningSelected: true,
  },
  {
    day: "Wednesday",
    amSelected: false,
    pmSelected: false,
    eveningSelected: true,
  },
  {
    day: "Thursday",
    amSelected: false,
    pmSelected: false,
    eveningSelected: false,
  },
  {
    day: "Friday",
    amSelected: false,
    pmSelected: false,
    eveningSelected: false,
  },
  {
    day: "Saturday",
    amSelected: true,
    pmSelected: true,
    eveningSelected: false,
  },
  {
    day: "Sunday",
    amSelected: true,
    pmSelected: true,
    eveningSelected: false,
  },
];

export const leadHead = {
  undefined: "Your search requirements",
  1: "Your search requirements",
  2: "Your household information",
  3: "Right to rent",
  4: "Viewing availability",
};

// const DEFAULT_LEAD_SEARCH = {
//   searchRequirements: {
//     maximumBudget: null,
//     idealMoveInDate: null,
//     areasLookingIn: null,
//     furnishingPreference: null,
//     bedRoomsNeeded: null,
//   },
//   householdInformation: {
//     numberOfAdults: null,
//     numberOfChildren: null,
//     relationship: null,
//     adultsEmploymentStatus: null,
//     adultsDetails: null,
//   },
//   availability: {
//     mondays: { am: false, pm: false, evening: false },
//     tuesdays: { am: false, pm: false, evening: false },
//     wednesdays: { am: false, pm: false, evening: false },
//     thursdays: { am: false, pm: false, evening: false },
//     fridays: { am: false, pm: false, evening: false },
//     saturdays: { am: false, pm: false, evening: false },
//     sundays: { am: false, pm: false, evening: false },
//   },
//   propertyId: null,
//   invitedBy: null,
// };

// export const CREATE_LEAD_SEARCH = ({
//   searchRequirements = DEFAULT_LEAD_SEARCH.searchRequirements,
//   householdInformation = DEFAULT_LEAD_SEARCH.householdInformation,
//   availability = DEFAULT_LEAD_SEARCH.availability,
//   propertyId = DEFAULT_LEAD_SEARCH.propertyId,
//   invitedBy = DEFAULT_LEAD_SEARCH.invitedBy,
// }) => ({
//   searchRequirements: {
//     maximumBudget: searchRequirements.maximumBudget,
//     idealMoveInDate: searchRequirements.idealMoveInDate,
//     areasLookingIn: searchRequirements.areasLookingIn,
//     furnishingPreference: searchRequirements.furnishingPreference,
//     bedRoomsNeeded: searchRequirements.bedRoomsNeeded,
//   },
//   householdInformation: {
//     numberOfAdults: householdInformation.numberOfAdults,
//     numberOfChildren: householdInformation.numberOfChildren,
//     relationship: householdInformation.relationship,
//     adultsEmploymentStatus: householdInformation.adultsEmploymentStatus,
//     adultsDetails: householdInformation.adultsDetails,
//   },
//   availability: {
//     mondays: {
//       am: availability.mondays?.am,
//       pm: availability.mondays?.pm,
//       evening: availability.mondays?.evening,
//     },
//     tuesdays: {
//       am: availability.tuesdays?.am,
//       pm: availability.tuesdays?.pm,
//       evening: availability.tuesdays?.evening,
//     },
//     wednesdays: {
//       am: availability.wednesdays?.am,
//       pm: availability.wednesdays?.pm,
//       evening: availability.wednesdays?.evening,
//     },
//     thursdays: {
//       am: availability.thursdays?.am,
//       pm: availability.thursdays?.pm,
//       evening: availability.thursdays?.evening,
//     },
//     fridays: {
//       am: availability.fridays?.am,
//       pm: availability.fridays?.pm,
//       evening: availability.fridays?.evening,
//     },
//     saturdays: {
//       am: availability.saturdays?.am,
//       pm: availability.saturdays?.pm,
//       evening: availability.saturdays?.evening,
//     },
//     sundays: {
//       am: availability.sundays?.am,
//       pm: availability.sundays?.pm,
//       evening: availability.sundays?.evening,
//     },
//   },
//   propertyId: propertyId,
//   invitedBy: invitedBy,
// });
export const DEFAULT_LEAD_SEARCH = {
  searchRequirements: {
    maximumBudget: null,
    idealMoveInDate: null,
    areasLookingIn: null,
    furnishingPreference: null,
    bedRoomsNeeded: null,
    mustHaves: null,
  },
  householdInformation: {
    numberOfAdults: null,
    numberOfChildren: null,
    relationship: null,
    adultsEmploymentStatus: null,
    adultsDetails: null,
    employmentStatusExplanation: null,
    canProvideGuarantor: null
  },
  rightToRentInformation: {
    adultsDetails: [{
      isBritishOrIrishCitizen: null,
      hasRightToShareCode: null,
      shareCode: null,
      dob: null,
      hasImmigrationDocuments: null,
    }]
  },
  availability: {
    mondays: { am: false, pm: false, evening: false },
    tuesdays: { am: false, pm: false, evening: false },
    wednesdays: { am: false, pm: false, evening: false },
    thursdays: { am: false, pm: false, evening: false },
    fridays: { am: false, pm: false, evening: false },
    saturdays: { am: false, pm: false, evening: false },
    sundays: { am: false, pm: false, evening: false },
  },
};

export const CREATE_LEAD_SEARCH = (data = {}) => {
  if (!data) return DEFAULT_LEAD_SEARCH;
  const { searchRequirements, householdInformation, availability, rightToRentInformation } = data;

  return {
    searchRequirements: {
      maximumBudget:
        searchRequirements?.maximumBudget ??
        DEFAULT_LEAD_SEARCH?.searchRequirements.maximumBudget,
      idealMoveInDate: searchRequirements?.idealMoveInDate
        ? moment(searchRequirements?.idealMoveInDate).format("YYYY-MM-DD")
        : DEFAULT_LEAD_SEARCH?.searchRequirements.idealMoveInDate,
      areasLookingIn:
        searchRequirements?.areasLookingIn ??
        DEFAULT_LEAD_SEARCH.searchRequirements.areasLookingIn,
      furnishingPreference:
        searchRequirements?.furnishingPreference ??
        DEFAULT_LEAD_SEARCH?.searchRequirements.furnishingPreference,
      bedRoomsNeeded:
        searchRequirements?.bedRoomsNeeded ??
        DEFAULT_LEAD_SEARCH?.searchRequirements.bedRoomsNeeded,
      mustHaves:
        searchRequirements?.mustHaves ??
        DEFAULT_LEAD_SEARCH?.searchRequirements.mustHaves,
    },
    householdInformation: {
      numberOfAdults:
        householdInformation?.numberOfAdults ??
        DEFAULT_LEAD_SEARCH?.householdInformation.numberOfAdults,
      numberOfChildren:
        householdInformation?.numberOfChildren ??
        DEFAULT_LEAD_SEARCH.householdInformation.numberOfChildren,
      relationship:
        householdInformation?.relationship ??
        DEFAULT_LEAD_SEARCH.householdInformation.relationship,
      adultsEmploymentStatus:
        householdInformation?.adultsEmploymentStatus ??
        DEFAULT_LEAD_SEARCH.householdInformation.adultsEmploymentStatus,
      adultsDetails:
        householdInformation?.adultsDetails ??
        DEFAULT_LEAD_SEARCH.householdInformation.adultsDetails,
      employmentStatusExplanation:
        householdInformation?.employmentStatusExplanation ??
        DEFAULT_LEAD_SEARCH.householdInformation.employmentStatusExplanation,
      canProvideGuarantor:
        householdInformation?.canProvideGuarantor ??
        DEFAULT_LEAD_SEARCH.householdInformation.canProvideGuarantor,

    },
    rightToRentInformation: {
      adultsDetails: Array.isArray(rightToRentInformation?.adultsDetails) 
        ? rightToRentInformation.adultsDetails.map(adult => ({
            ...adult,
            dob: adult?.dob ? moment(adult.dob).format("YYYY-MM-DD") : null,  
          }))
        : DEFAULT_LEAD_SEARCH.rightToRentInformation.adultsDetails,  },
    
    availability: {
      mondays: {
        am:
          availability?.mondays?.am ??
          DEFAULT_LEAD_SEARCH.availability.mondays.am,
        pm:
          availability?.mondays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.mondays.pm,
        evening:
          availability?.mondays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.mondays.evening,
      },
      tuesdays: {
        am:
          availability?.tuesdays?.am ??
          DEFAULT_LEAD_SEARCH.availability.tuesdays.am,
        pm:
          availability?.tuesdays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.tuesdays.pm,
        evening:
          availability?.tuesdays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.tuesdays.evening,
      },
      wednesdays: {
        am:
          availability?.wednesdays?.am ??
          DEFAULT_LEAD_SEARCH.availability.wednesdays.am,
        pm:
          availability?.wednesdays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.wednesdays.pm,
        evening:
          availability?.wednesdays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.wednesdays.evening,
      },
      thursdays: {
        am:
          availability?.thursdays?.am ??
          DEFAULT_LEAD_SEARCH.availability.thursdays.am,
        pm:
          availability?.thursdays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.thursdays.pm,
        evening:
          availability?.thursdays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.thursdays.evening,
      },
      fridays: {
        am:
          availability?.fridays?.am ??
          DEFAULT_LEAD_SEARCH.availability.fridays.am,
        pm:
          availability?.fridays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.fridays.pm,
        evening:
          availability?.fridays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.fridays.evening,
      },
      saturdays: {
        am:
          availability?.saturdays?.am ??
          DEFAULT_LEAD_SEARCH.availability.saturdays.am,
        pm:
          availability?.saturdays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.saturdays.pm,
        evening:
          availability?.saturdays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.saturdays.evening,
      },
      sundays: {
        am:
          availability?.sundays?.am ??
          DEFAULT_LEAD_SEARCH.availability.sundays.am,
        pm:
          availability?.sundays?.pm ??
          DEFAULT_LEAD_SEARCH.availability.sundays.pm,
        evening:
          availability?.sundays?.evening ??
          DEFAULT_LEAD_SEARCH.availability.sundays.evening,
      },
    },
  };
};

export const LEADS_MESSAGES_ON_CREATE_PAGE = {
  1: {
    Top: `All information can be edited once you have created your account.`,
    Bottom: "",
  },
  2: {
    Top: "",
    Bottom: (
      <>
        <p>
          Once completed, you’ll be able to share your search criteria with any
          UK letting agent in the click of a button.
        </p>{" "}
        <p>
          If you enquire about a property listed by an agent using Rently, they
          will be able to view your RentlySearch profile immediately.
        </p>
      </>
    ),
  },
  3: {
    Top: `Before you can rent in England, you need to prove your right to rent to your landlord.`,
    Bottom: ""
  },
  4: {
    Top: `Please enter your typical viewing availability.`,
    Bottom: `Don’t worry, this can be edited at anytime.`,
  },
};

export const LEAD_STATUS = {
  completed: "Completed",
  formSend: "Lead form sent",
  tenantAdded: "Tenant Added",
  noInterest: "No longer interested",
  reminderSend: "Reminder Sent",
  viewingBooked: "Viewing Booked",
};

export const LEADS_FURNITURE = {
  furnished: "Furnished",
  unfurnished: "Unfurnished",
  either: "Either",
};

export const createObjectOptions = (data = LEADS_FURNITURE) => {
  const newData = Object.keys(data).map((key) => ({
    _id: data[key],
    label: data[key],
    value: data[key],
  }));

  return newData;
};

export const BEDROOMS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

export const RELATIONSHIP = [
  { value: "Family", label: "Family" },
  { value: "Friends", label: "Friends" },
  { value: "Partner", label: "Partner" },
];

export const ADULTS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6+" },
];

export const CHILDREN = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6+" },
];

export const YESORNO = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const HOW_MANY = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5+" },
];

export const RENTLY_HELP_LINK = "https://rentlysupport.notion.site/Help-Centre-Renters-93d8a23b59eb4c18bd19c15c073ce2e0"